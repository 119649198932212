<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            resizeFn: null
        }
    },
    created() {
        let token = this.getUrlParms('t')
        //alert("token==="+token);
        sessionStorage.setItem("t", token);
    },
    mounted() {
        const documentWidth = document.body.offsetWidth;
        const ratio = documentWidth / 1920;
        if (documentWidth > 1920) {
            document.body.style.transform = `scale(${ratio}, ${ratio})`
        }
        this.resizeFn = this.$debounce(function () {
            const documentWidth = document.body.offsetWidth;
            const ratio = documentWidth / 1920;
            if (documentWidth > 1920) {
                document.body.style.transform = `scale(${ratio}, ${ratio})`
            }
        }, 200)
        window.addEventListener('resize',this.resizeFn);
    },
    methods:{
        getUrlParms(e) {
            let url = window.location.href;
            let p = url.split('?')[1]
            let keyValue = p.split('&');
            let obj = {};
            for (let i = 0; i < keyValue.length; i++) {
                let item = keyValue[i].split('=');
                let key = item[0];
                let value = item[1];
                obj[key] = value;
            }
            return obj[e] || undefined
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn);
    }
}
</script>

<style lang="less">
html,body {
    height: 100%;
    width: 100%;
    padding:0;
    margin:0;
}
html {
    font-size: 20px;
}
body {
    transform-origin: left top;
    background-size: 100% 100%;
}
#app {
    height: 100%;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
