import Vue from 'vue'
import App from './App'
import router from './router'
import iView from 'iview';
import './assets/less/index.less';
import echarts from 'echarts'
import img from './lib/img'
import utils from "./lib/utils";
import VueResource from 'vue-resource'
//import request from '@/utils/requestUtil/deviceUtil';

Vue.prototype.$echarts = function (el) {
    return echarts.init(el, null, {renderer: 'svg'})
}
Vue.prototype.$images = img
Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(iView);
Vue.use(utils)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

//增加公用请求头jtoken

//方法一、
Vue.http.headers.common['jtoken'] = decodeURIComponent(sessionStorage.getItem("t"));

//方法二、
// Vue.http.interceptors.push((request, next) => {
//     request.headers.set('jtoken', sessionStorage.getItem("t"));
//     console.log("Vue.http.interceptors.push.request=="+JSON.stringify(request))
// })
