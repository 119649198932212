import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/home';

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/hrscreen'
        },
        {
            path: '',
            name: 'home',
            component: home,
            children: [
                {
                    path: '/page1',
                    name: 'page1',
                    component: () => import('@/views/page1')
                },
                {
                    path: '/safe',
                    name: 'safe',
                    component: () => import('@/views/safe')
                },
                {
                    path: '/hrscreen',
                    name: 'hrscreen',
                    component: () => import('@/views/hrscreen')
                },
                {
                    path: '/hrstatistics',
                    name: 'hrstatistics',
                    component: () => import('@/views/hrstatistics')
                },
                {
                    path: '/page3',
                    name: 'page3',
                    component: () => import('@/views/page3')
                },
                {
                    path: '/video',
                    name: 'video',
                    component: () => import('@/views/video')
                },
                {
                    path: '/evaluation',
                    name: 'evaluation',
                    component: () => import('@/views/evaluation')
                },
                {
                    path: '/situation',
                    name: 'situation',
                    component: () => import('@/views/situation')
                },
                {
                    path: '/doubleSafe',
                    name: 'doubleSafe',
                    component: () => import('@/views/doubleSafe')
                },
                {
                    path: '/statistics',
                    name: 'statistics',
                    component: () => import('@/views/statistics')
                },
                {
                    path: '/inspection',
                    name: 'inspection',
                    component: () => import('@/views/inspection')
                }
            ]
        }
    ]
})
export default router
