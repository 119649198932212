<template>
    <div style="height:100%;">
        <div class="header">
            <div v-if='false' class="selectRange">
                <Menu mode="horizontal" @on-select="(name) =>$router.push(name)" :active-name="$route.name">
                    <MenuItem name="page1">
                        page1
                    </MenuItem>
                    <MenuItem name="page2">
                        page2
                    </MenuItem>
                    <MenuItem name="page3">
                        page3
                    </MenuItem>
                </Menu>
            </div>
            <div class="header-title" id="companyName1" style="width: 100%;text-align: center">
                招聘系统报表平台
            </div>
            <div v-if="false" class="selectRange">
                <Menu mode="horizontal" @on-select="handleSelect" :active-name="activeName">
                    <MenuItem name="day">
                        昨日
                    </MenuItem>
                    <MenuItem name="week">
                        近一周
                    </MenuItem>
                    <MenuItem name="month">
                        近一月
                    </MenuItem>
                    <Submenu name="4">
                        <template slot="title">
                            <Icon type="ios-settings-outline" size="24" color="#60C2D4"/>
                        </template>
                        <MenuItem name="filter">筛选</MenuItem>
                    </Submenu>

                </Menu>
            </div>
        </div>
        <Modal
                v-model="modal"
                title="选择时间"
                :mask-closable="false"
                @on-ok="getMonthBetween(startTime,endTime)"
        >
            <DatePicker @on-change="pickStartDate" :options="optionStart" type="date" placeholder="选择开始日期"
                        style="width: 200px"></DatePicker>
            <span style="padding:0 20px;color:#75deef">至</span>
            <DatePicker @on-change="pickEndDate" :options="optionEnd" type="date" placeholder="选择结束日期"
                        style="width: 200px"></DatePicker>
        </Modal>
        <div class="page-left">
           <div class="menu" id="hrscreen" @click="goPage('/hrscreen')">&nbsp;</div>
           <!--<div class="menu" id="hrstatistics" @click="goPage('/hrstatistics')">报表统计</div>-->
           <!-- <div class="menu" id="evaluation" @click="goPage('/evaluation')">安全评估</div>
            <div class="menu" id="situation" @click="goPage('/situation')">安全态势</div>
            <div class="menu" id="doubleSafe" @click="goPage('/doubleSafe')">双重预防</div>
            <div class="menu" id="statistics" @click="goPage('/hrscreen')">报表统计</div>
            <div class="menu" id="inspection" @click="goPage('/inspection')">安全巡检</div>
            <div class="menu" id="video" @click="goPage('/video')">视频汇聚</div>-->
        </div>


        <div class="page">
            <router-view v-if="flag" :selectRangeDate='selectRangeDate'></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {
                activeName: 'month',// 默认显示近一月
                modal: false,
                flag: false,
                selectRangeDate: [],
                startTime: '',
                endTime: '',
                optionStart: {
                    disabledDate(date) { // 禁止选择今天之后的日期
                        return date && date.valueOf() > Date.now() - 86400000;
                    }
                },
                optionEnd: {},
                resizeFn: null
            }
        },

        mounted() {


            //首页默认被选中
            var page = window.location.href;
            page = page.substring(page.lastIndexOf("/") + 1, page.length);
            //alert(page);
            switch (page) {
                case "hrscreen":
                    document.getElementById("hrscreen").style.color = "yellow";
                    break;
                case "hrstatistics":
                    document.getElementById("hrstatistics").style.color = "yellow";
                    break;
                case "safe":
                    //document.getElementById("safe").style.color = "yellow";
                    break;
                case "evaluation":
                    //document.getElementById("evaluation").style.color = "yellow";
                    break;
                case "situation":
                    //document.getElementById("situation").style.color = "yellow";
                    break;
                case "doubleSafe":
                    //document.getElementById("doubleSafe").style.color = "yellow";
                    break;
                case "statistics":
                    document.getElementById("statistics").style.color = "yellow";
                    break;
                case "inspection":
                    //document.getElementById("inspection").style.color = "yellow";
                    break;
                case "video":
                    //document.getElementById("video").style.color = "yellow";
                    break;
                default:
                    document.getElementById("hrscreen").style.color = "yellow";
                    break;
            }

            window.addEventListener('resize', this.resizeFn);
            this.handleSelect(this.activeName); // 默认显示近一个月
            //this.nameInit();
        },
        methods: {

            //教育局信息初始化加载
            nameInit() {
                this.$http
                    .get('https://api.luojie-safe.cn/api/user/info')
                    .then(
                        response => {//successcallback
                            document.getElementById("companyName1").innerHTML = response.body.data.companyName+"-安全防控大数据平台";
                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })
            },

            goPage(e) {
                this.$router.push({
                    path: e
                })
                //改变菜单选中颜色
                e = e.substring(1, e.length);
                document.getElementById("hrscreen").style.color = "white";
                document.getElementById("hrstatistics").style.color = "white";
                document.getElementById(e).style.color = "yellow";
            },
            pickStartDate(date) { // 选择开始时间的回调
                this.startTime = date;
                this.optionEnd = {
                    disabledDate(d) { // 禁止选择开始时间之前的日期
                        return d && d.valueOf() < new Date(date).valueOf() - 86400000;
                    }
                }
            },
            pickEndDate(date) { // 选择结束时间的回调
                this.endTime = date;
            },
            getMonthBetween(start, end) {  // 获取开始时间和结束时间之内的所有月份
                this.selectRangeDate = [];
                let s = start.split("-");  // 字符串装换数组
                let e = end.split("-");
                let date = new Date();
                let min = date.setFullYear(s[0], s[1] - 1); // 设置最小时间
                let max = date.setFullYear(e[0], e[1] - 1); // 设置最大时间
                let curr = min;
                while (curr <= max) {  // 循环添加月份
                    var month = curr.getMonth();
                    var arr = [curr.getFullYear(), month + 1];
                    this.selectRangeDate.push(arr);
                    curr.setMonth(month + 1);
                }
            },
            getDays(day) {// 获取天数
                let arr = [];
                for (let i = -day; i < 0; i++) { // 循环添加天数
                    let today = new Date();// 获取今天
                    let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * i;
                    today.setTime(targetday_milliseconds); //设置i天前的时间
                    let tYear = today.getFullYear();
                    let tMonth = today.getMonth();
                    let tDate = today.getDate();
                    let date = [tYear, tMonth + 1, tDate];
                    arr.push(date);
                }
                return arr
            },
            handleSelect(name) {
                switch (name) {
                    case 'day':

                        break;
                    case 'week':

                        this.selectRangeDate = this.getDays(7);// 获取近一周的天数
                        this.flag = true;

                        break;
                    case 'month':
                        this.selectRangeDate = this.getDays(30);// 获取近一个月的天数
                        this.flag = true;
                        break;
                    case 'filter':
                        this.modal = true;
                        break;
                    default:
                        break;
                }

            }
        },
    }
</script>

<style lang="less">
    .ivu-modal {
        .ivu-modal-content {
            background: #071332;

            .ivu-modal-header {
                border-bottom: 1px solid #1a3c58;

                .ivu-modal-header-inner {
                    color: #75deef;
                }
            }

            .ivu-modal-body {
                text-align: center;

                .ivu-icon {
                    color: #75deef
                }

                .ivu-modal-confirm-body {
                    padding-left: 0;
                    color: #75deef
                }

                .ivu-input {
                    background-color: rgba(0, 0, 0, 0);
                    border: 1px solid #1a3c58;
                    color: #75deef;

                    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                        color: #75deef;
                    }

                    &::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                        color: #75deef;
                    }

                    &::-moz-placeholder { /* Mozilla Firefox 19+ */
                        color: #75deef;
                    }

                    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #75deef;
                    }
                }

                .ivu-picker-panel-body {
                    background: #071332;

                    .ivu-date-picker-header {
                        color: #75deef;
                        border-bottom: 1px solid #1a3c58
                    }

                    .ivu-date-picker-cells-cell {
                        color: #75deef;

                        &:hover em {
                            background: #1a3c58;
                        }
                    }

                    .ivu-date-picker-cells-cell-disabled {
                        background: rgba(0, 0, 0, 0);
                        color: #eee
                    }

                    .ivu-date-picker-cells-focused em {
                        box-shadow: 0 0 0 1px #1a3c58 inset;

                        &::after {
                            background: #1a3c58;
                        }
                    }
                }
            }

            .ivu-modal-footer {
                border-top: 1px solid #1a3c58;

                .ivu-btn-primary {
                    color: #75deef;
                    background: #1a3c58;
                }

                .ivu-btn-text {
                    color: #ddd;

                    &:hover {
                        color: #75deef;
                        background: #1a3c58;
                    }
                }
            }
        }

    }

    .header {
        height: 80px;
        background: #03044A;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
            color: #75deef;
            font-size: 30px;
            letter-spacing: 10px;
        }

        .selectRange {
            height: 100%;

            .ivu-menu-horizontal {
                background: rgba(255, 255, 255, 0);

                &::after {
                    height: 0;
                }

                .ivu-menu-item-active {
                    border-bottom: 2px solid #264e5e;

                }

                .ivu-menu-item, .ivu-menu-submenu {
                    color: #75deef;

                    &:hover {
                        border-bottom: 2px solid #264e5e;
                    }
                }

                .ivu-select-dropdown {
                    background: #09102E;

                    .ivu-menu-item {
                        color: #75deef;

                        &:hover {

                            border-bottom: 2px solid #264e5e;
                            background-color: rgba(255, 255, 255, 0);
                        }
                    }
                }

                .ivu-menu-submenu-title {
                    i {
                        margin-right: 0;
                    }

                    .ivu-icon-ios-arrow-down {
                        display: none;
                    }
                }
            }
        }
    }

    .menu {
        width: 60px;
        height: 80px;
        background: #03044A;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
        color: rgb(255, 255, 255);
    }

    #one, #two {
        float: left;
        margin-left: 2px;

    }

    #daxues, #zhuanyes {
        display: none;
        margin-top: 2px;
    }

    .page-left {
        width: 60px;
        background: #03044A;
        height: calc(~ '100% - 80px');
        display: inline-block;
        float: left;
    }

    .page {
        height: calc(~ '100% - 80px');
        width: calc(~ '100% - 60px');
        display: inline-block;
    }
</style>
